import React from 'react';

const SkeletonText = () => {
  return (
    <div className="skeleton">
    </div>
  );
};

export default SkeletonText;
